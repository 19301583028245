import { nanoid } from 'nanoid'
import type { MaybeRef, Reactive } from 'vue'
import { copyObjectWithDescriptors } from '@manager'

export type RepeaterItem<T extends Record<string, any>> = T & {
  __id: string
  __status: REPEATER_ITEM_STATUS
  __createdAt: string
  __modifiedAt: string | null
  __evaluatedAt: string | null
  __deletedAt: string | null
  objectReferenceId: string
}

export interface UseRepeaterOptions<T extends Record<string, any>> {
  min?: number
  max?: number
  onAdd?: (
    item: Reactive<RepeaterItem<T>>,
    index: number,
    array: RepeaterItem<T>[],
  ) => void
  onUpdate?: (
    item: Reactive<RepeaterItem<T>>,
    index: number,
    array: RepeaterItem<T>[],
  ) => void
  onRemove?: (
    item: Reactive<RepeaterItem<T>>,
    index: number,
    array: RepeaterItem<T>[],
  ) => void
}

export const RESERVED_KEYS = [
  '__id',
  '__status',
  '__createdAt',
  '__modifiedAt',
  '__evaluatedAt',
  '__deletedAt',
  'objectReferenceId',
]

export enum REPEATER_ITEM_STATUS {
  CREATED = 'created',
  EDITED = 'edited',
  DELETED = 'deleted',
  GENERATED = 'generated',
}

export const useRepeater = <T extends Record<string, any>>(
  initialValue: MaybeRef<T[]> = [],
  options: UseRepeaterOptions<T> = {},
) => {
  const { min = 0, max = Infinity, onAdd, onUpdate, onRemove } = options

  const items = ref(initialValue) as Ref<RepeaterItem<T>[]>
  const filteredItems = computed(() =>
    items.value.filter(
      (item) => item.__status !== REPEATER_ITEM_STATUS.DELETED,
    ),
  )

  const canAdd = computed(() => filteredItems.value.length < max)
  const canRemove = computed(() => filteredItems.value.length > min)

  init(items.value)

  const add = (item: T = {} as T): void => {
    if (filteredItems.value.length >= max) return

    const newItem = _createItem(item)
    const length = items.value.push(newItem)
    onAdd?.(reactive(newItem), length - 1, items.value as RepeaterItem<T>[])
  }

  const get = (index: number): RepeaterItem<T> | undefined => {
    return items.value[index]
  }

  const getById = (id: string): RepeaterItem<T> | undefined => {
    return items.value.find((item) => item.__id === id)
  }

  const update = (item: RepeaterItem<T>, newItem: Partial<T>): void => {
    Object.assign(item, newItem)

    item.__status = newItem.__status ?? REPEATER_ITEM_STATUS.EDITED
    item.__modifiedAt = new Date().toISOString()
    item.__evaluatedAt = null
    item.__deletedAt = null

    onUpdate?.(
      reactive(item),
      items.value.indexOf(item as RepeaterItem<T>),
      items.value as RepeaterItem<T>[],
    )
  }

  const updateById = (id: string, item: Partial<T>): void => {
    const originalItem = getById(id)
    if (originalItem) {
      update(originalItem, item)
    }
  }

  const remove = (
    item: RepeaterItem<T>,
    index: number = items.value.indexOf(item),
  ): void => {
    if (filteredItems.value.length <= min) return

    if (item.objectReferenceId !== '0') {
      for (const key in item) {
        if (!RESERVED_KEYS.includes(key)) {
          delete item[key]
        }
      }

      item.__status = REPEATER_ITEM_STATUS.DELETED
      item.__modifiedAt = null
      item.__evaluatedAt = null
      item.__deletedAt = new Date().toISOString()
    } else {
      items.value.splice(index, 1)
    }

    onRemove?.(reactive(item), index, items.value)
  }

  const removeById = (id: string): void => {
    const item = getById(id)
    if (item) remove(item)
  }

  const __stored = ref<RepeaterItem<T>[]>([]) as Ref<RepeaterItem<T>[]>
  tryOnActivated(() => {
    // We only want to restore the data if all items are deleted and there are items to be restored.
    if (
      filteredItems.value.length === 0 &&
      __stored.value.filter((i) => i.__deleted === false).length > 0
    ) {
      items.value = __stored.value
    }
  })
  tryOnDeactivated(() => {
    // Copy the items keeping the descriptors, and save them to be restored later.
    __stored.value = items.value.map((o) => copyObjectWithDescriptors(o))
    __stored.value.forEach((item) => removeById(item.__id))
  })

  return {
    items,
    filteredItems,
    canAdd,
    canRemove,
    add,
    get,
    getById,
    update,
    updateById,
    remove,
    removeById,
  }

  function _createItem(item: T): RepeaterItem<T> {
    Object.defineProperties(item, {
      objectReferenceId: {
        enumerable: true,
        writable: true,
        value: item.objectReferenceId ?? '0',
      },
      __id: {
        enumerable: false,
        writable: true,
        value: item.__id ?? nanoid(),
      },
      __status: {
        enumerable: false,
        writable: true,
        value: item.__status ?? REPEATER_ITEM_STATUS.CREATED,
      },
      __createdAt: {
        enumerable: false,
        writable: true,
        value: item.__createdAt ?? new Date().toISOString(),
      },
      __modifiedAt: {
        enumerable: false,
        writable: true,
        value: item.__modifiedAt ?? null,
      },
      __evaluatedAt: {
        enumerable: false,
        writable: true,
        value: item.__evaluatedAt ?? null,
      },
      __deletedAt: {
        enumerable: false,
        writable: true,
        value: item.__deletedAt ?? null,
      },
    })
    return item as RepeaterItem<T>
  }

  function init(items: T[]): void {
    items.forEach((item, index, array) => {
      const newItem = _createItem(item)
      onAdd?.(reactive(newItem), index, array as RepeaterItem<T>[])
    })
  }
}
